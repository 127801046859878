import React from 'react';
import { MdOutlineAddPhotoAlternate } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { toggleAddPopup } from 'redux/slices/popupsSlice';

import { MenuButton } from '../ui-elements/MenuButton';

export const Menu = () => {
  const dispatch = useDispatch();
  return (
    <div className="drop-shadow-xl rounded-[50px] p-1 border-t border-r-2 border-b shadow-lg flex flex-col gap-2.5 max-[900px]:hidden ">
      <MenuButton onClick={() => dispatch(toggleAddPopup())}>
        <MdOutlineAddPhotoAlternate />
      </MenuButton>
    </div>
  );
};
