import React from "react";
import { MdOutlineAddPhotoAlternate } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";

import { AsideSwitcher } from "components/AsideSwitcher";
import { Logo } from "components/Header/Logo";
import { toggleAddPopup, toggleAuthorInfoPopup } from "redux/slices/popupsSlice";
import { MenuButton } from "ui-elements/MenuButton";

import { Navigation } from "./Navigation";
import { HiMenu } from "react-icons/hi";

export const Header = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const { mainTitle } = useSelector((state) => state.author.authorInfo);

  return (
    <header className="z-50 flex items-center gap-2 border-b drop-shadow-xl md:gap-4">
      <Logo />
      <div className="mr-auto">
        <h1 className="text-xs font-bold md:text-base lg:text-xl xl:text-2xl">
          {mainTitle}
        </h1>
      </div>
      <Navigation className="hidden md:flex" />

      {user &&
        (user.email === process.env.REACT_APP_MENULIS ||
          user.email === process.env.REACT_APP_ALEX ||
          user.email === process.env.REACT_APP_JULIA) && (
          <MenuButton
            className="min-[901px]:hidden"
            onClick={() => dispatch(toggleAddPopup())}
          >
            <MdOutlineAddPhotoAlternate />
          </MenuButton>
        )}
      <MenuButton className="md:hidden" onClick={() =>dispatch(toggleAuthorInfoPopup())}>
        <HiMenu className="md:size-6" />
      </MenuButton>
      {/* <AsideSwitcher className="md:hidden" /> */}
    </header>
  );
};
