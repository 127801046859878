import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isShowAddPopup: false,
  isShowDeleteCategoryPopup: false,
  isShowDeletePopup: false,
  isShowAboutAuthorPopup: false,
  isShowEditPopup: false,
  isShowAuthorInfoPopup: false,
};

const popupsSlice = createSlice({
  name: "popups",
  initialState,
  reducers: {
    toggleAddPopup(state) {
      state.isShowAddPopup = !state.isShowAddPopup;
    },
    toggleDeleteCategoryPopup(state) {
      state.isShowDeleteCategoryPopup = !state.isShowDeleteCategoryPopup;
    },
    toggleDeletePopup(state) {
      state.isShowDeletePopup = !state.isShowDeletePopup;
    },
    toggleAboutAuthorPopup(state) {
      state.isShowAboutAuthorPopup = !state.isShowAboutAuthorPopup;
    },
    toggleEditPopup(state) {
      state.isShowEditPopup = !state.isShowEditPopup;
    },
    toggleAuthorInfoPopup(state) {
      state.isShowAuthorInfoPopup = !state.isShowAuthorInfoPopup;
    },
  },
});

export const {
  toggleAddPopup,
  toggleDeleteCategoryPopup,
  toggleDeletePopup,
  toggleAboutAuthorPopup,
  toggleEditPopup,
  toggleAuthorInfoPopup,
} = popupsSlice.actions;
export default popupsSlice.reducer;
