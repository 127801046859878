import React, { useEffect, useRef, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { FaInstagram } from "react-icons/fa";
import { IoMdCheckmark } from "react-icons/io";
import { MdEdit } from "react-icons/md";
import Resizer from "react-image-file-resizer";
import { useDispatch, useSelector } from "react-redux";

import { storage } from "firebase.config";
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import {
  getAuthorInfo,
  setIsEditDescriptionTextMod,
  setIsEditSubtitleMod,
  setIsEditTitleMod,
} from "redux/slices/aboutAuthorSlice";
import { toggleAuthorInfoPopup } from "redux/slices/popupsSlice";

import ImageDataService from "../../services/images.service";

export const AuthorInfo = () => {
  const dispatch = useDispatch();
  const {
    authorInfo,
    isEditTitleMod,
    isEditSubtitleMod,
    isEditDescriptionTextMod,
  } = useSelector((state) => state.author);
  const user = useSelector((state) => state.user.user);
  const inputRef = useRef(null);
  const [authorAvatar, setAuthorAvatar] = useState(null);
  const [successUpload, setSuccessUpload] = useState(false);
  const [newTitle, setNewTitle] = useState(authorInfo.title);
  const [newSubtitle, setNewSubtitle] = useState(authorInfo.subtitle);
  const [newDescriptionText, setNewDescriptionText] = useState(
    authorInfo.descriptionText,
  );
  const inputTitleRef = useRef(null);
  const inputSubtitleRef = useRef(null);
  const inputDescriptionTextRef = useRef(null);

  const uploadPhoto = (e) => {
    const imageFile = e.target.files[0];

    if (imageFile) {
      const storageRef = ref(storage, `Author/${Date.now()}-${imageFile.name}`);
      try {
        Resizer.imageFileResizer(
          imageFile,
          1500,
          1500,
          "JPEG",
          90,
          0,
          (uri) => {
            const uploadTask = uploadBytesResumable(storageRef, uri);

            uploadTask.on(
              "state_changed",
              (snapshot) => {
                const uploadProgress =
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log(`Upload is ${uploadProgress}% done`);
              },
              (error) => {
                console.log(error);
              },
              () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                  setAuthorAvatar(downloadURL);
                  setSuccessUpload(true);
                });
              },
            );
          },
          "file",
          1500,
          1500,
        );
      } catch (error) {
        console.log(error);
      }
    }
  };

  const submitPhoto = async () => {
    deleteObject(ref(storage, authorInfo?.imageURL));

    const newAuthorInfo = {
      descriptionText: authorInfo.descriptionText,
      imageURL: authorAvatar,
      subtitle: authorInfo.subtitle,
      title: authorInfo.title,
    };

    await ImageDataService.changeAuthorAvatar(authorInfo.id, newAuthorInfo);

    setSuccessUpload(false);
    dispatch(getAuthorInfo());
  };

  const submitTitle = async () => {
    const newAuthorInfo = {
      descriptionText: authorInfo.descriptionText,
      imageURL: authorInfo.imageURL,
      subtitle: authorInfo.subtitle,
      title: newTitle,
    };

    await ImageDataService.changeAuthorTitle(authorInfo.id, newAuthorInfo);

    dispatch(getAuthorInfo());
  };

  const submitSubtitle = async () => {
    const newAuthorInfo = {
      descriptionText: authorInfo.descriptionText,
      imageURL: authorInfo.imageURL,
      subtitle: newSubtitle,
      title: authorInfo.title,
    };

    await ImageDataService.changeAuthorSubtitle(authorInfo.id, newAuthorInfo);

    dispatch(getAuthorInfo());
  };

  const submitDescriptionText = async () => {
    const newAuthorInfo = {
      descriptionText: newDescriptionText,
      imageURL: authorInfo.imageURL,
      subtitle: authorInfo.subtitle,
      title: authorInfo.title,
    };

    await ImageDataService.changeAuthorDescriptionText(
      authorInfo.id,
      newAuthorInfo,
    );

    dispatch(getAuthorInfo());
  };

  useEffect(() => {
    if (authorAvatar) {
      submitPhoto();
    }
  }, [authorAvatar]);

  useEffect(() => {
    setNewTitle(authorInfo.title);
    setNewSubtitle(authorInfo.subtitle);
    setNewDescriptionText(authorInfo.descriptionText);
  }, [authorInfo.title, authorInfo.subtitle, authorInfo.descriptionText]);

  const autoHeight = (e) => {
    e.target.style.height = "auto";
    e.target.style.height = `${e.target.scrollHeight}px`;
  };

  // const changeTextareaHeight = () => {
  //   textareaRef.current.style.height = "auto";
  //   textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
  // };

  const changeTitleHeight = () => {
    inputTitleRef.current.style.height = "auto";
    inputTitleRef.current.style.height = `${inputTitleRef.current.scrollHeight}px`;
  };

  const changeSubtitleHeight = () => {
    inputSubtitleRef.current.style.height = "auto";
    inputSubtitleRef.current.style.height = `${inputSubtitleRef.current.scrollHeight}px`;
  };

  const changeDescriptionTextHeight = () => {
    inputDescriptionTextRef.current.style.height = "auto";
    inputDescriptionTextRef.current.style.height = `${inputDescriptionTextRef.current.scrollHeight}px`;
  };

  useEffect(() => {
    if (isEditTitleMod) {
      changeTitleHeight();
    }

    if (isEditSubtitleMod) {
      changeSubtitleHeight();
    }

    if (isEditDescriptionTextMod) {
      changeDescriptionTextHeight();
    }
  }, [isEditTitleMod, isEditSubtitleMod, isEditDescriptionTextMod]);

  return (
    <div className="fixed h-screen w-screen flex-col gap-2 overflow-y-auto bg-black bg-opacity-30 pt-20 drop-shadow-sm backdrop-blur-md md:flex md:flex-row">
      <button
        className="absolute right-2 top-2 z-20 p-1 text-3xl leading-none"
        onClick={() => {
          dispatch(toggleAuthorInfoPopup());
        }}
      >
        <AiOutlineClose />
      </button>
      <div className="group relative h-auto min-h-20 min-w-[50%]  md:w-1/2 [&_textarea]:drop-shadow-md">
        {successUpload && (
          <div className="absolute -bottom-2.5 left-2.5 text-xl text-green-700">
            <span>Success upload!</span>
          </div>
        )}
        <img
          className="h-auto object-center"
          src={authorInfo.imageURL}
          alt=""
        />
        {user &&
          (user.email === process.env.REACT_APP_MENULIS ||
            user.email === process.env.REACT_APP_ALEX ||
            user.email === process.env.REACT_APP_JULIA) && (
            <div className="absolute bottom-1 left-2 flex gap-5 opacity-0 group-hover:opacity-100">
              <input
                className="hidden"
                type="file"
                onChange={uploadPhoto}
                ref={inputRef}
              />
              <button onClick={() => inputRef.current.click()}>
                <MdEdit />
              </button>
            </div>
          )}
      </div>
      <div className="flex flex-auto flex-col p-2 md:p-0 md:pr-2">
        <div className="group flex justify-between gap-1">
          {isEditTitleMod ? (
            <textarea
              className="mb-2 w-full bg-white bg-opacity-15 text-xl md:text-2xl"
              value={newTitle}
              onChange={(e) => {
                setNewTitle(e.target.value);
                autoHeight(e);
              }}
              autoFocus
              ref={inputTitleRef}
            />
          ) : (
            authorInfo.title && (
              <h2 className="mb-2 text-xl md:text-2xl">{authorInfo.title}</h2>
            )
          )}
          {user &&
          (user.email === process.env.REACT_APP_MENULIS ||
            user.email === process.env.REACT_APP_ALEX ||
            user.email === process.env.REACT_APP_JULIA) ? (
            isEditTitleMod ? (
              <div className="flex gap-2 h-fit">
                <button
                  onClick={() => {
                    submitTitle();
                    dispatch(setIsEditTitleMod(false));
                  }}
                >
                  <IoMdCheckmark />
                </button>
                <button
                  onClick={() => {
                    setNewTitle(authorInfo.title);
                    dispatch(setIsEditTitleMod(false));
                  }}
                >
                  <AiOutlineClose />
                </button>
              </div>
            ) : (
              <button
                className="ml-auto flex h-fit items-start opacity-0 group-hover:opacity-100"
                onClick={() => dispatch(setIsEditTitleMod(true))}
              >
                <MdEdit />
              </button>
            )
          ) : null}
        </div>
        <div className="group flex justify-between gap-1">
          {isEditSubtitleMod ? (
            <textarea
              className="w-full bg-white bg-opacity-15 text-lg md:mb-2 md:text-xl"
              value={newSubtitle}
              onChange={(e) => {
                setNewSubtitle(e.target.value);
                autoHeight(e);
              }}
              autoFocus
              ref={inputSubtitleRef}
            />
          ) : (
            authorInfo.subtitle && (
              <h3 className="text-lg md:mb-2 md:text-xl">
                {authorInfo.subtitle}
              </h3>
            )
          )}
          {user &&
          (user.email === process.env.REACT_APP_MENULIS ||
            user.email === process.env.REACT_APP_ALEX ||
            user.email === process.env.REACT_APP_JULIA) ? (
            isEditSubtitleMod ? (
              <div className="flex gap-2 h-fit">
                <button
                  onClick={() => {
                    submitSubtitle();
                    dispatch(setIsEditSubtitleMod(false));
                  }}
                >
                  <IoMdCheckmark />
                </button>
                <button
                  onClick={() => {
                    setNewSubtitle(authorInfo.subtitle);
                    dispatch(setIsEditSubtitleMod(false));
                  }}
                >
                  <AiOutlineClose />
                </button>
              </div>
            ) : (
              <button
                className="ml-auto flex h-fit items-start opacity-0 group-hover:opacity-100"
                onClick={() => dispatch(setIsEditSubtitleMod(true))}
              >
                <MdEdit />
              </button>
            )
          ) : null}
        </div>
        <div className="group flex justify-between gap-1">
          {isEditDescriptionTextMod ? (
            <textarea
              className="w-full bg-white bg-opacity-15"
              value={newDescriptionText}
              onChange={(e) => {
                setNewDescriptionText(e.target.value);
                autoHeight(e);
              }}
              autoFocus
              ref={inputDescriptionTextRef}
            />
          ) : (
            <p>{authorInfo.descriptionText}</p>
          )}
          {user &&
          (user.email === process.env.REACT_APP_MENULIS ||
            user.email === process.env.REACT_APP_ALEX ||
            user.email === process.env.REACT_APP_JULIA) ? (
            isEditDescriptionTextMod ? (
              <div className="flex gap-2 h-fit">
                <button
                  onClick={() => {
                    submitDescriptionText();
                    dispatch(setIsEditDescriptionTextMod(false));
                  }}
                >
                  <IoMdCheckmark />
                </button>
                <button
                  onClick={() => {
                    setNewDescriptionText(authorInfo.descriptionText);
                    dispatch(setIsEditDescriptionTextMod(false));
                  }}
                >
                  <AiOutlineClose />
                </button>
              </div>
            ) : (
              <button
                className="flex h-fit items-start opacity-0 group-hover:opacity-100"
                onClick={() => dispatch(setIsEditDescriptionTextMod(true))}
              >
                <MdEdit />
              </button>
            )
          ) : null}
        </div>

        <div className="mt-5 flex justify-end gap-2 text-2xl md:text-3xl">
          <a
            href="https://www.instagram.com/juli_safemakeup/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagram />
          </a>
        </div>
      </div>
    </div>
  );
};
