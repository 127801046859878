import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import ImageDataService from '../../services/images.service';

const initialState = {
  authorInfo: {},
  isEditTitleMod: false,
  isEditSubtitleMod: false,
  isEditDescriptionTextMod: false,
};

export const getAuthorInfo = createAsyncThunk(
  'authorInfo/getAuthorInfo',
  async (_, { rejectWithValue, dispatch }) => {
    const data = await ImageDataService.getAuthorInfo();
    dispatch(
      setAboutAuthorInfo(
        data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))[0],
      ),
    );
  },
);

export const aboutAuthorSlice = createSlice({
  name: 'authorInfo',
  initialState,
  reducers: {
    setAboutAuthorInfo: (state, action) => {
      state.authorInfo = action.payload;
    },
    setIsEditTitleMod: (state, action) => {
      state.isEditTitleMod = action.payload;
    },
    setIsEditSubtitleMod: (state, action) => {
      state.isEditSubtitleMod = action.payload;
    },
    setIsEditDescriptionTextMod: (state, action) => {
      state.isEditDescriptionTextMod = action.payload;
    },
  },
  extraReducers: {
    [getAuthorInfo.pending]: () => console.log('author info pending'),
    [getAuthorInfo.fulfilled]: () => console.log('author info fulfilled'),
    [getAuthorInfo.rejected]: () => console.log('author info rejected'),
  },
});

export const {
  setAboutAuthorInfo,
  setIsEditTitleMod,
  setIsEditSubtitleMod,
  setIsEditDescriptionTextMod,
} = aboutAuthorSlice.actions;
export default aboutAuthorSlice.reducer;
