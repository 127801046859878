import { configureStore } from "@reduxjs/toolkit";

import aboutAuthorSlice from "./slices/aboutAuthorSlice";
import aside from "./slices/aside";
import bannerSlice from "./slices/bannerSlice";
import categorySlice from "./slices/categorySlice";
import imagesSlice from "./slices/imagesSlice";
import menuSlice from "./slices/menuSlice";
import popupsSlice from "./slices/popupsSlice";
import sliderSlice from "./slices/sliderSlice";
import userSlice from "./slices/userSlice";

export const store = configureStore({
  reducer: {
    aside,
    user: userSlice,
    images: imagesSlice,
    categories: categorySlice,
    popups: popupsSlice,
    author: aboutAuthorSlice,
    banner: bannerSlice,
    slider: sliderSlice,
    menu: menuSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
