import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { CategoryDescription } from "components/CategoryDescription";
import {
  setActiveCategoryID,
  setActiveCategoryName,
} from "redux/slices/categorySlice";
import {
  getImages,
  getMoreImagesByCategory,
  getMoreImagesInGallery,
  setIsLoading,
} from "redux/slices/imagesSlice";

import { PhotoSet } from "./PhotoSet";

export const Gallery = () => {
  const dispatch = useDispatch();
  const galleryWrapperRef = useRef();
  const { images } = useSelector((state) => state.images);
  const { activeCategoryID, activeCategoryName } = useSelector(
    (state) => state.categories,
  );
  const [numColumns, setNumColumns] = useState(3);

  const dividedArrays = Array.from({ length: numColumns }, () => []);

  images.forEach((image, index) => {
    const column = index % numColumns;
    dividedArrays[column].push(image);
  });

  const calculateNumColumns = () => {
    if (window.innerWidth >= 1400) {
      setNumColumns(5);
    } else if (window.innerWidth >= 1000) {
      setNumColumns(4);
    } else if (window.innerWidth >= 600) {
      setNumColumns(3);
    } else if (window.innerWidth < 600) {
      setNumColumns(2);
    }
  };

  useEffect(() => {
    calculateNumColumns();
    window.addEventListener("resize", calculateNumColumns);
    return () => {
      window.removeEventListener("resize", calculateNumColumns);
    };
  }, []);

  useEffect(() => {
    dispatch(getImages());
    dispatch(setActiveCategoryID(null));
    dispatch(setActiveCategoryName("all"));
  }, []);

  const handleScroll = (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.currentTarget;

    if (
      scrollHeight - scrollTop <= clientHeight + 5 ||
      scrollHeight === clientHeight
    ) {
      dispatch(setIsLoading(true));
      if (activeCategoryID === null) {
        dispatch(getMoreImagesInGallery());
      } else {
        dispatch(getMoreImagesByCategory(activeCategoryName));
      }
    } else {
      dispatch(setIsLoading(false));
    }
  };

  const fewImages = () => {
    const { scrollTop, clientHeight, scrollHeight } = galleryWrapperRef.current;

    if (scrollHeight - scrollTop <= clientHeight) {
      dispatch(setIsLoading(true));
      if (activeCategoryID === null) {
        dispatch(getMoreImagesInGallery());
      } else {
        dispatch(getMoreImagesByCategory(activeCategoryName));
      }
    } else {
      dispatch(setIsLoading(false));
    }
  };

  useEffect(() => {
    const galleryWrapper = galleryWrapperRef.current;
    galleryWrapper.addEventListener("scroll", handleScroll);
    return () => {
      galleryWrapper.removeEventListener("scroll", handleScroll);
    };
  }, [activeCategoryID]);

  useEffect(() => {
    fewImages();
  }, [images]);

  return (
    <div
      className="flex w-full flex-col gap-2 overflow-y-auto py-2 md:gap-5 md:py-5"
      ref={galleryWrapperRef}
    >
      {activeCategoryID !== null && <CategoryDescription />}
      <div
        className="grid w-full gap-2"
        style={{ gridTemplateColumns: `repeat(${numColumns}, 1fr)` }}
      >
        {dividedArrays.map((columnImages, columnIndex) => (
          <div key={columnIndex}>
            {columnImages.map((item) => (
              <PhotoSet key={item.id} images={item} />
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};
