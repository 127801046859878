import React, { useEffect, useRef } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { useDispatch } from "react-redux";

import { toggleAside } from "redux/slices/aside";
import { cn } from "utils/cn";

import { Categories } from "./Categories";
import { Navigation } from "./Header/Navigation";

export const Aside = ({ className }) => {
  const dispatch = useDispatch();
  const asideRef = useRef(null);

  const outsideClick = (e) => {
    if (asideRef.current && !asideRef.current.contains(e.target)) {
      dispatch(toggleAside());
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", outsideClick);
    return () => {
      document.removeEventListener("mousedown", outsideClick);
    };
  }, []);

  return (
    <aside
      className={cn(
        "fixed right-0 top-0 z-10 flex h-full w-full flex-col overflow-hidden bg-gradient-to-r from-[#00000033] to-[#00000000] p-3 backdrop-blur-lg min-[500px]:w-80 md:rounded-2xl md:border-b md:border-r-4 md:border-t md:p-5",
        className,
      )}
      ref={asideRef}
    >
      <header>
        <button
          className="ml-auto flex p-2"
          onClick={() => dispatch(toggleAside())}
        >
          <AiOutlineClose className="size-6" />
        </button>
      </header>
      <main className="flex flex-col gap-2">
        <Navigation className="md:hidden" />
        {/* <Categories /> */}
      </main>
    </aside>
  );
};
