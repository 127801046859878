import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  updateDoc,
  where,
} from 'firebase/firestore';

import { db } from '../firebase.config';

const ImagesCollectionRef = collection(db, 'Images');
const CategoriesCollectionRef = collection(db, 'Categories');
const AuthorInfoCollectionRef = collection(db, 'AboutAuthor');
const BannerRef = collection(db, 'Banner');

class ImageDataService {
  addImage = (newPhoto) => {
    return addDoc(ImagesCollectionRef, newPhoto);
  };
  getAllImages = async (imagesPerLoad) => {
    const images = [];
    const imagesQuery = query(
      ImagesCollectionRef,
      limit(imagesPerLoad),
      orderBy('createdAt', 'desc'),
    );

    const imagesSnapshot = await getDocs(imagesQuery);
    const lastVisible = imagesSnapshot.docs[imagesSnapshot.docs.length - 1];
    imagesSnapshot.forEach((image) => {
      images.push({ id: image.id, ...image.data() });
    });

    return { images, lastVisible };
  };
  getMoreImages = async (startAfterItem, imagesPerLoad) => {
    const images = [];
    const imagesQuery = query(
      ImagesCollectionRef,
      orderBy('createdAt', 'desc'),
      startAfter(startAfterItem),
      limit(imagesPerLoad),
    );

    const imagesSnapshot = await getDocs(imagesQuery);
    const lastVisible = imagesSnapshot.docs[imagesSnapshot.docs.length - 1];
    imagesSnapshot.forEach((image) => {
      images.push({ id: image.id, ...image.data() });
    });

    return { images, lastVisible };
  };
  getImage = (id) => {
    const imageDoc = doc(db, 'Images', id);
    return getDoc(imageDoc);
  };
  getImagesByCategory = async (category, imagesPerLoad) => {
    const images = [];
    const imagesQuery = query(
      ImagesCollectionRef,
      limit(imagesPerLoad),
      orderBy('createdAt', 'desc'),
      where('category', '==', category),
    );

    const imagesSnapshot = await getDocs(imagesQuery);
    const lastVisible = imagesSnapshot.docs[imagesSnapshot.docs.length - 1];
    imagesSnapshot.forEach((image) => {
      images.push({ id: image.id, ...image.data() });
    });

    return { images, lastVisible };
  };
  getMoreImagesByCategory = async (category, startAfterr, imagesPerLoad) => {
    const images = [];
    const imagesQuery = query(
      ImagesCollectionRef,
      orderBy('createdAt', 'desc'),
      startAfter(startAfterr),
      limit(imagesPerLoad),
      where('category', '==', category),
    );

    const imagesSnapshot = await getDocs(imagesQuery);
    const lastVisible = imagesSnapshot.docs[imagesSnapshot.docs.length - 1];
    imagesSnapshot.forEach((image) => {
      images.push({ id: image.id, ...image.data() });
    });

    return { images, lastVisible };
  };
  addCategory = (newCategory) => {
    return addDoc(CategoriesCollectionRef, newCategory);
  };
  getAllCategories = () => {
    return getDocs(CategoriesCollectionRef);
  };
  updateImage = (id, updatedPhoto) => {
    const imageDoc = doc(db, 'Images', id);
    return updateDoc(imageDoc, updatedPhoto);
  };
  deleteImage = (id) => {
    const imageDoc = doc(db, 'Images', id);
    return deleteDoc(imageDoc);
  };
  deleteCategory = (categoryId) => {
    const categoryDoc = doc(db, 'Categories', categoryId);
    return deleteDoc(categoryDoc);
  };
  updateCategory = (categoryId, updatedCategory) => {
    const categoryDoc = doc(db, 'Categories', categoryId);
    return updateDoc(categoryDoc, updatedCategory);
  };
  getAuthorInfo = async () => {
    const authorInfoDoc = query(AuthorInfoCollectionRef);
    const authorInfoSnapshot = await getDocs(authorInfoDoc);
    return authorInfoSnapshot;
  };
  changeAuthorAvatar = (id, updatedAuthorInfo) => {
    const authorInfoDoc = doc(db, 'AboutAuthor', id);
    return updateDoc(authorInfoDoc, updatedAuthorInfo);
  };
  changeAuthorTitle = (id, title) => {
    const authorInfoDoc = doc(db, 'AboutAuthor', id);
    return updateDoc(authorInfoDoc, title);
  };
  changeAuthorSubtitle = (id, subtitle) => {
    const authorInfoDoc = doc(db, 'AboutAuthor', id);
    return updateDoc(authorInfoDoc, subtitle);
  };
  changeAuthorDescriptionText = (id, descriptionText) => {
    const authorInfoDoc = doc(db, 'AboutAuthor', id);
    return updateDoc(authorInfoDoc, descriptionText);
  };
  getBannerBg = async () => {
    const bannerDoc = query(BannerRef);
    const bannerSnapshot = await getDocs(bannerDoc);
    return bannerSnapshot;
  };
  changeBannerBg = (id, updatedBannerBg) => {
    const bannerDoc = doc(db, 'Banner', id);
    return updateDoc(bannerDoc, updatedBannerBg);
  };
}

const instance = new ImageDataService();
export default instance;
