import React, { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import {
  MdCloudUpload,
  MdDeleteForever,
  MdOutlineAddPhotoAlternate,
} from "react-icons/md";
import Resizer from "react-image-file-resizer";
import { useDispatch, useSelector } from "react-redux";

import Dropdown from "components/Dropdown";
import { storage } from "firebase.config";
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { getCategories } from "redux/slices/categorySlice";
import { toggleAddPopup } from "redux/slices/popupsSlice";
import ImageDataService from "services/images.service";

import styles from "./addPhoto.module.scss";

export const AddPhoto = () => {
  const dispatch = useDispatch();
  const { categories } = useSelector(
    (state) => state.categories,
  );

  const [imageAssets, setImageAssets] = useState([]);
  const [percentage, setPercentage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [fields, setFields] = useState(false);
  const [message, setMessage] = useState("");
  const [alertStatus, setAlertStatus] = useState("danger");
  const [category, setCategory] = useState("");
  const [disableButton, setDisableButton] = useState(true);

  const [currentImage, setCurrentImage] = useState(null);

  const uploadImage = async (e) => {
    setIsLoading(true);

    const files = e.target.files;

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      if (file) {
        const storageRef = ref(storage, `Images/${Date.now()}-${file.name}`);
        try {
          Resizer.imageFileResizer(
            file,
            1500,
            1500,
            "JPEG",
            90,
            0,
            (uri) => {
              const uploadTask = uploadBytesResumable(storageRef, uri);

              uploadTask.on(
                "state_changed",
                (snapshot) => {
                  const progress =
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                  setPercentage(progress);
                },
                (error) => {
                  console.log(error);
                  setIsLoading(false);
                  setFields(true);
                  setMessage("Error uploading image: try again");
                  setAlertStatus("danger");
                  setTimeout(() => {
                    setFields(false);
                    setIsLoading(false);
                  }, 5000);
                },
                () => {
                  getDownloadURL(uploadTask.snapshot.ref).then(
                    (downloadURL) => {
                      setImageAssets((prevImages) => [
                        ...prevImages,
                        downloadURL,
                      ]);
                      setIsLoading(false);
                      setFields(true);
                      setDisableButton(false);
                      setMessage("Image uploaded successfully");
                      setAlertStatus("success");
                      setTimeout(() => {
                        setFields(false);
                      }, 5000);
                    },
                  );
                },
              );
            },
            "file",
            1500,
            1500,
          );
        } catch (error) {
          console.log(error);
        }
      }
    }
  };

  const deleteImage = async (url) => {
    setIsLoading(true);
    const deleteRef = ref(storage, url);
    setImageAssets((prevImages) => [
      ...prevImages.filter((item) => item !== url),
    ]);
    deleteObject(deleteRef).then(() => {
      setIsLoading(false);
      setFields(true);
      setMessage("Image deleted successfully");
      setAlertStatus("success");
      setTimeout(() => {
        setFields(false);
      }, 5000);
    });
  };

  const deleteAllImages = async () => {
    setIsLoading(true);

    try {
      const deletePromises = imageAssets.map((url) => {
        const deleteRef = ref(storage, url);
        return deleteObject(deleteRef);
      });
      await Promise.all(deletePromises);
      setImageAssets([]);
      setIsLoading(false);
      setFields(true);
      setMessage("All images deleted successfully");
      setAlertStatus("success");
      setTimeout(() => {
        setFields(false);
      }, 5000);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setFields(true);
      setMessage("Error deleting images: try again");
      setAlertStatus("danger");
      setTimeout(() => {
        setFields(false);
        setIsLoading(false);
      }, 5000);
    }
  };

  const handleSubmit = async () => {
    setMessage(null);

    if (!imageAssets) {
      setFields(true);
      setMessage("Image is mandatory!");
      setAlertStatus("danger");
      setTimeout(() => {
        setFields(false);
        setIsLoading(false);
      }, 3000);
      return;
    }

    const newCategory = {
      name: category.toUpperCase(),
      description: "",
    };

    const newImage = {
      imageAssets,
      category: category.toUpperCase(),
      createdAt: new Date().toISOString(),
    };

    try {
      if (
        !categories.find(
          (cat) => cat.name.toUpperCase() === category.toUpperCase(),
        ) &&
        category !== ""
      ) {
        await ImageDataService.addCategory(newCategory);
      }
      setDisableButton(true);
      await ImageDataService.addImage(newImage);
      clearData();
      dispatch(getCategories());
      setFields(true);
      setMessage("image added successfully!");
      setAlertStatus("success");
      setTimeout(() => {
        setFields(false);
      }, 4000);
    } catch (err) {
      setFields(true);
      setMessage(err.message);
      setAlertStatus("danger");
      setTimeout(() => {
        setFields(false);
      }, 2000);
    }
    setCategory("");
  };

  const clearData = () => {
    setImageAssets([]);
    setCategory("");
  };

  const dragStartHandler = (e, index) => {
    console.log("drag start", index);
    setCurrentImage(index);
  };

  const dragLeaveHandler = (e) => {
    console.log("drag leave");
    e.target.style.opacity = "1";
  };

  const dragEndHandler = (e) => {
    console.log("drag end");
    e.target.style.opacity = "1";
  };

  const dragOverHandler = (e) => {
    e.preventDefault();
    console.log("drag over");
    e.target.style.opacity = "0.5";
  };

  const dropHandler = (e, targetIndex) => {
    e.preventDefault();
    e.target.style.opacity = "1";
    const imageList = [...imageAssets];
    const draggedImage = imageList[currentImage];
    imageList.splice(currentImage, 1);
    imageList.splice(targetIndex, 0, draggedImage);
    setImageAssets(imageList);
    setCurrentImage(null);
  };

  return (
    <div className="popupWrapper">
      <form className="popup" onSubmit={(e) => e.preventDefault()}>
        <button
          className="close"
          onClick={() => {
            dispatch(toggleAddPopup());
            imageAssets && deleteAllImages();
          }}
        >
          <AiOutlineClose />
        </button>
        {fields && (
          <p
            className={
              alertStatus === "danger" ? styles.danger : styles.success
            }
          >
            {message}
          </p>
        )}
        {isLoading ? (
          <p className={styles.percentage}>{percentage.toFixed(0)}%</p>
        ) : (
          <>
            {imageAssets.length === 0 ? (
              <>
                <label htmlFor="uploadImage">
                  <MdCloudUpload />
                </label>
                <input
                  id="uploadImage"
                  style={{
                    height: "0",
                    width: "0",
                    overflow: "hidden",
                  }}
                  type="file"
                  name="uploadImage"
                  onChange={(e) => uploadImage(e)}
                  accept="image/*"
                  multiple
                />
              </>
            ) : (
              <div
                className="grid grid-cols-2 gap-4 overflow-y-auto"
              >
                {imageAssets.map((url, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "5px",
                      width: "100%",
                      breakInside: "avoid",
                      marginBottom: "20px",
                      position: "relative",
                      cursor: "grab",
                    }}
                    onDragStart={(e) => dragStartHandler(e, index)}
                    onDragLeave={(e) => dragLeaveHandler(e)}
                    onDragEnd={(e) => dragEndHandler(e)}
                    onDragOver={(e) => dragOverHandler(e)}
                    onDrop={(e) => dropHandler(e, index)}
                    draggable={true}
                  >
                    <img
                      className="max-h-96 w-full object-cover rounded-md pointer-events-none object-center"
                      src={url}
                      alt={`preview-${index}`}
                    />
                    <button
                      className={styles.deleteButton}
                      onClick={() => deleteImage(url)}
                    >
                      <MdDeleteForever />
                    </button>
                  </div>
                ))}
              </div>
            )}
          </>
        )}
        <div className={styles.writeInfo}>
          <Dropdown
            categories={categories}
            category={category}
            setCategory={setCategory}
          />
        </div>
        {imageAssets.length > 0 && !disableButton && (
          <button
            className="greenButton btn"
            type="submit"
            onClick={() => {
              handleSubmit();
            }}
          >
            Add Images
            <div className="btn__icon">
              <MdOutlineAddPhotoAlternate />
            </div>
          </button>
        )}
      </form>
    </div>
  );
};
