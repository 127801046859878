import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import ImageDataService from '../../services/images.service';

const initialState = {
  bannerBgImage: '',
};

export const getBannerBgImage = createAsyncThunk(
  'banner/getBannerBgImage',
  async (_, { rejectWithValue, dispatch }) => {
    const data = await ImageDataService.getBannerBg();
    dispatch(
      setBannerBgImage(
        data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))[0],
      ),
    );
  },
);

const bannerSlice = createSlice({
  name: 'banner',
  initialState: initialState,
  reducers: {
    setBannerBgImage: (state, action) => {
      state.bannerBgImage = action.payload;
    },
  },
  extraReducers: {
    [getBannerBgImage.pending]: () => console.log('banner pending'),
    [getBannerBgImage.fulfilled]: () => console.log('banner fulfilled'),
    [getBannerBgImage.rejected]: () => console.log('banner rejected'),
  },
});

export const { setBannerBgImage } = bannerSlice.actions;
export default bannerSlice.reducer;
