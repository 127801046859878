import React from "react";
import { useDispatch } from "react-redux";

import { closeAside } from "redux/slices/aside";
import { toggleAuthorInfoPopup } from "redux/slices/popupsSlice";
import { cn } from "utils/cn";

export const Navigation = ({ className }) => {
  const dispatch = useDispatch();

  return (
    <nav
      className={cn("navigation relative flex items-center gap-1", className)}
    >
      <button
        className="md:rounded-2xl md:px-2.5 md:py-1 md:hover:bg-black md:hover:bg-opacity-20"
        onClick={() => {
          dispatch(toggleAuthorInfoPopup());
          dispatch(closeAside());
        }}
      >
        Contacts
      </button>
    </nav>
  );
};
