import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { storage } from "firebase.config";
import { deleteObject, ref } from "firebase/storage";
import ImageDataService from "services/images.service";

import {
  setImageToDelete,
  setImages,
  setSelectedPhotoSet,
} from "../../../redux/slices/imagesSlice";
import { toggleDeletePopup } from "../../../redux/slices/popupsSlice";
import styles from "./deleteConformation.module.scss";

const DeleteConformation = () => {
  const dispatch = useDispatch();
  const { selectedPhotoSet, imageToDelete, images } = useSelector(
    (state) => state.images,
  );

  const deletePhoto = async (id, imageAsset) => {
    try {
      await ImageDataService.deleteImage(id);

      imageAsset.forEach((item) => {
        deleteObject(ref(storage, item));
      });

      dispatch(setSelectedPhotoSet(null));
      dispatch(setImageToDelete([]));
      dispatch(setImages(images.filter((image) => image.id !== id)));

      console.log("Фотографія видалена успішно");

    } catch (error) {
      console.error("Помилка під час видалення фотографії:", error);
    }
  };

  return (
    <div className={`popupWrapper ${styles.deletePopup}`}>
      <div className="popup">
        <p>Are you sure you want to delete this photo?</p>
        <div className={styles.buttons}>
          <button
            className="cancel"
            onClick={() => {
              dispatch(toggleDeletePopup(false));
              dispatch(setSelectedPhotoSet(null));
              dispatch(setImageToDelete([]));
            }}
          >
            Cancel
          </button>
          <button
            className="delete"
            onClick={() => {
              dispatch(toggleDeletePopup(false));
              deletePhoto(selectedPhotoSet, imageToDelete);
            }}
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteConformation;
