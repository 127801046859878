import React from 'react';
import { AiFillDelete } from 'react-icons/ai';
import { MdEdit } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import {
  setImageToDelete,
  setSelectedPhotoSet,
} from 'redux/slices/imagesSlice';
import { toggleDeletePopup, toggleEditPopup } from 'redux/slices/popupsSlice';

import { Photo } from './Photo';

export const PhotoSet = ({ images }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);

  return (
    <div
      className="h-fit w-full relative cursor-zoom-in group"
      style={{
        marginBottom: `${images.imageAssets.length * 8}px`,
      }}
    >
      {images.imageAssets.map((item, index) => (
        <Photo key={item} image={item} index={index} />
      ))}
      {user &&
        (user.email === process.env.REACT_APP_MENULIS ||
          user.email === process.env.REACT_APP_ALEX ||
          user.email === process.env.REACT_APP_JULIA) && (
          <div className="text-white flex absolute bottom-1 left-1 transition gap-2 text-lg z-10 opacity-0 group-hover:opacity-100">
            <button
            className='bg-[#00000088] rounded-full flex items-center justify-center p-1'
              onClick={() => {
                dispatch(setSelectedPhotoSet(images.id));
                dispatch(toggleDeletePopup(true));
                dispatch(setImageToDelete(images.imageAssets));
              }}
            >
              <AiFillDelete />
            </button>
            <button
            className='bg-[#00000088] rounded-full flex items-center justify-center p-1'
              onClick={() => {
                dispatch(toggleEditPopup(true));
                dispatch(setSelectedPhotoSet(images.id));
              }}
            >
              <MdEdit />
            </button>
          </div>
        )}
    </div>
  );
};
