import React from "react";
import { useSelector } from "react-redux";


import { AddPhoto } from "./AddPhoto";
import DeleteCategoryConformation from "./DeleteCategoryConformation";
import DeleteConformation from "./DeleteConformation";
import { EditPopup } from "./EditPhotoPopup";
import styles from "./popups.module.scss";
import { AuthorInfo } from "./AuthorInfo";

export const Popups = () => {
  const {
    isShowAddPopup,
    isShowEditPopup,
    isShowDeletePopup,
    isShowDeleteCategoryPopup,
    isShowAuthorInfoPopup,
  } = useSelector((state) => state.popups);
  return (
    <div className={styles.popups}>
      {isShowAddPopup && <AddPhoto />}
      {isShowEditPopup && <EditPopup />}
      {isShowDeletePopup && <DeleteConformation />}
      {isShowDeleteCategoryPopup && <DeleteCategoryConformation />}
      {isShowAuthorInfoPopup && <AuthorInfo />}
    </div>
  );
};
