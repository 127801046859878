import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isShowSlider: false,
};

const sliderSlice = createSlice({
  name: 'slider',
  initialState,
  reducers: {
    setIsShowSlider: (state, action) => {
      state.isShowSlider = action.payload;
    },
  },
});

export const { setIsShowSlider } = sliderSlice.actions;

export default sliderSlice.reducer;
