import React, { useEffect } from "react";

import { Gallery } from "components/Gallery";
import { AllRightsReserved } from "components/AllRightsReserved";
import { useDispatch } from "react-redux";
import { getAuthorInfo } from "../redux/slices/aboutAuthorSlice";

export const Home = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAuthorInfo());
  }, []);
  return (
    <div className="relative flex-col flex flex-auto justify-between overflow-hidden">
      <Gallery />
      <AllRightsReserved />
    </div>
  );
};
