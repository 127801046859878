import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { setSliderSInitialPhoto } from "redux/slices/imagesSlice";
import { setIsShowSlider } from "redux/slices/sliderSlice";
import { Loader } from "ui-elements/Loader";
import { cn } from "utils/cn";

export const Photo = ({ image, index }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  const handleImageLoad = () => {
    setIsLoading(false);
  };
  return (
    <div
      className={cn(
        "relative h-full w-full cursor-pointer overflow-hidden rounded-lg object-cover shadow-lg shadow-[#00000088]",
        isLoading && "min-h-28",
      )}
      style={{
        bottom: `calc(0px - ${index * 10}px)`,
        zIndex: `${index === 0 ? 10 : -index}`,
        transform: `scale(${index === 0 ? 1 : 0.99 - index * 0.03})`,
        opacity: `${index === 0 ? 1 : 0.7 - index * 0.1}`,
        position: `${index === 0 ? "relative" : "absolute"}`,
      }}
      onClick={() => {
        dispatch(setIsShowSlider(true));
        dispatch(setSliderSInitialPhoto(image));
      }}
    >
      {isLoading && (
        <div className="absolute left-0 top-0 h-full w-full">
          <Loader />
        </div>
      )}
      <img
        className={cn(
          "",
          isLoading ? "hidden" : "block",
        )}
        src={image}
        alt=""
        onLoad={handleImageLoad}
      />
    </div>
  );
};
