import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isOpenMobileMenu: false,
};

export const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    setIsOpenMobileMenu: (state, action) => {
      state.isOpenMobileMenu = action.payload;
    },
  },
});

export const { setIsOpenMobileMenu } = menuSlice.actions;
export default menuSlice.reducer;
