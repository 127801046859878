import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteCategory } from 'redux/slices/categorySlice';

import { toggleDeleteCategoryPopup } from '../../../redux/slices/popupsSlice';
import styles from './deleteCategoryConformation.module.scss';

const DeleteCategoryConformation = () => {
  const dispatch = useDispatch();
  const { activeCategoryName, activeCategoryID } = useSelector(
    (state) => state.categories,
  );
  return (
    <div className={`popupWrapper ${styles.deleteCategoryPopup}`}>
      <div className="popup">
        <p>Are you sure you want to delete "{activeCategoryName}" category</p>
        <div className={styles.buttons}>
          <button
            className="cancel"
            onClick={() => {
              dispatch(toggleDeleteCategoryPopup());
            }}
          >
            Cancel
          </button>
          <button
            className="delete"
            onClick={() => {
              dispatch(deleteCategory(activeCategoryID));
              dispatch(toggleDeleteCategoryPopup());
            }}
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};
export default DeleteCategoryConformation;
