import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import ImageDataService from '../../services/images.service';

const initialState = {
  images: [],
  isLoading: false,
  selectedPhotoSet: null,
  imageToDelete: [],
  imagesForGallerySlider: [],
  sliderSInitialPhoto: '',
  imagesPerLoad: 6,
  startAfterItem: {},
  isLastImage: false,
};

export const getImages = createAsyncThunk(
  'images/getImages',
  async (_, { rejectWithValue, dispatch }) => {
    const data = await ImageDataService.getAllImages(
      initialState.imagesPerLoad,
    );

    dispatch(setImages([]));
    dispatch(setImages([...data.images]));
    const lastVisibleId = data.lastVisible;

    dispatch(setStartAfter(lastVisibleId));

    data.images.length < initialState.imagesPerLoad
      ? dispatch(setLastImage(true))
      : dispatch(setLastImage(false));

    const ImgForSlider = data.images.map((item) => item.imageAssets).flat();
    dispatch(setImagesForGallerySlider(ImgForSlider));
  },
);

export const getMoreImagesInGallery = createAsyncThunk(
  'images/getMoreImagesInGallery',
  async (_, { rejectWithValue, dispatch, getState }) => {
    const {
      startAfterItem,
      imagesPerLoad,
      images,
      isLastImage,
      imagesForGallerySlider,
    } = getState().images;
    if (!isLastImage) {
      const data = await ImageDataService.getMoreImages(
        startAfterItem,
        imagesPerLoad,
      );
      dispatch(setImages([...images, ...data.images]));
      dispatch(setStartAfter(data.lastVisible));

      data.images.length < initialState.imagesPerLoad
        ? dispatch(setLastImage(true))
        : dispatch(setLastImage(false));
      const ImgForSlider = data.images.map((item) => item.imageAssets).flat();
      dispatch(
        setImagesForGallerySlider([...imagesForGallerySlider, ...ImgForSlider]),
      );
    }
  },
);

export const getImagesByCategory = createAsyncThunk(
  'images/getImagesByCategory',
  async (categoryName, { rejectWithValue, dispatch }) => {
    const data = await ImageDataService.getImagesByCategory(
      categoryName,
      initialState.imagesPerLoad,
    );
    dispatch(setImages([]));
    dispatch(setImages([...data.images]));
    const lastVisibleId = data.lastVisible;

    dispatch(setStartAfter(lastVisibleId));

    data.images.length < initialState.imagesPerLoad
      ? dispatch(setLastImage(true))
      : dispatch(setLastImage(false));

    const ImgForSlider = data.images.map((item) => item.imageAssets).flat();
    dispatch(setImagesForGallerySlider(ImgForSlider));
  },
);

export const getMoreImagesByCategory = createAsyncThunk(
  'images/getMoreImagesByCategory',
  async (categoryName, { rejectWithValue, dispatch, getState }) => {
    const {
      startAfterItem,
      imagesPerLoad,
      images,
      isLastImage,
      imagesForGallerySlider,
    } = getState().images;
    if (!isLastImage) {
      const data = await ImageDataService.getMoreImagesByCategory(
        categoryName,
        startAfterItem,
        imagesPerLoad,
      );
      dispatch(setImages([...images, ...data.images]));
      dispatch(setStartAfter(data.lastVisible));

      data.images.length < initialState.imagesPerLoad
        ? dispatch(setLastImage(true))
        : dispatch(setLastImage(false));
      const ImgForSlider = data.images.map((item) => item.imageAssets).flat();
      dispatch(
        setImagesForGallerySlider([...imagesForGallerySlider, ...ImgForSlider]),
      );
    }
  },
);

export const imagesSlice = createSlice({
  name: 'images',
  initialState,
  reducers: {
    setImages: (state, action) => {
      state.images = action.payload;
    },
    setSelectedPhotoSet: (state, action) => {
      state.selectedPhotoSet = action.payload;
    },
    setImageToDelete: (state, action) => {
      state.imageToDelete = action.payload;
    },
    setImagesForGallerySlider: (state, action) => {
      state.imagesForGallerySlider = action.payload;
    },
    setSliderSInitialPhoto: (state, action) => {
      state.sliderSInitialPhoto = action.payload;
    },
    setStartAfter: (state, action) => {
      state.startAfterItem = action.payload;
    },
    setLastImage: (state, action) => {
      state.isLastImage = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
  extraReducers: {
    [getImages.pending]: () => console.log('images pending'),
    [getImages.fulfilled]: () => console.log('images fulfilled'),
    [getImages.rejected]: () => console.log('images rejected'),

    [getImagesByCategory.pending]: () =>
      console.log('imagesByCategory pending'),
    [getImagesByCategory.fulfilled]: () =>
      console.log('imagesByCategory fulfilled'),
    [getImagesByCategory.rejected]: (err) =>
      console.log('imagesByCategory rejected', err),

    [getMoreImagesInGallery.pending]: () =>
      console.log('moreImagesInGallery pending'),
    [getMoreImagesInGallery.fulfilled]: () =>
      console.log('moreImagesInGallery fulfilled'),
    [getMoreImagesInGallery.rejected]: (error) =>
      console.log('moreImagesInGallery rejected', error),

    [getMoreImagesByCategory.pending]: () =>
      console.log('moreImagesByCategory pending'),
    [getMoreImagesByCategory.fulfilled]: () =>
      console.log('moreImagesByCategory fulfilled'),
    [getMoreImagesByCategory.rejected]: (error) =>
      console.log('moreImagesByCategory rejected', error),
  },
});

export const {
  setImages,
  setSelectedPhotoSet,
  setImageToDelete,
  setImagesForGallerySlider,
  setSliderSInitialPhoto,
  setStartAfter,
  setLastImage,
  setIsLoading,
} = imagesSlice.actions;
export default imagesSlice.reducer;
