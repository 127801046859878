import React, { useEffect, useRef, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { IoMdCheckmark } from "react-icons/io";
import { MdEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";

import {
  changeCategoryDescription,
  setIsCategoryDescription,
  setIsEditCategoryMod,
} from "redux/slices/categorySlice";
import { cn } from "utils/cn";

export const CategoryDescription = () => {
  const dispatch = useDispatch();
  const textareaRef = useRef();
  const { user } = useSelector((state) => state.user);
  const { activeCategoryID, categories, isEditCategoryMod } = useSelector(
    (state) => state.categories,
  );
  const [newCategoryDescription, setNewCategoryDescription] = useState("");

  const categoryDescription = categories.find(
    (category) => category.id === activeCategoryID,
  )?.description;

  useEffect(() => {
    if (categoryDescription) {
      dispatch(setIsCategoryDescription(true));
      setNewCategoryDescription(categoryDescription);
    } else {
      dispatch(setIsCategoryDescription(false));
    }
  }, [activeCategoryID]);

  useEffect(() => {
    if (isEditCategoryMod) {
      textareaRef.current.focus();
    }
  }, [isEditCategoryMod]);

  const changeTextareaHeight = () => {
    textareaRef.current.style.height = "auto";
    textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
  };

  useEffect(() => {
    if (isEditCategoryMod) {
      changeTextareaHeight();
    }
  }, [isEditCategoryMod]);

  return (
    <div
      className={cn(
        "flex items-start justify-between gap-2.5 drop-shadow-xl md:mr-10",
        !categoryDescription && !user ? "hidden" : "",
      )}
    >
      {isEditCategoryMod ? (
        <textarea
          className="w-full text-lg"
          value={newCategoryDescription}
          onChange={(e) => {
            setNewCategoryDescription(e.target.value);
            changeTextareaHeight();
          }}
          ref={textareaRef}
        />
      ) : (
        <p>{categoryDescription}</p>
      )}
      {user &&
      (user.email === process.env.REACT_APP_MENULIS ||
        user.email === process.env.REACT_APP_ALEX ||
        user.email === process.env.REACT_APP_JULIA) ? (
        isEditCategoryMod ? (
          <div className="flex gap-4">
            <button
              onClick={() => {
                dispatch(setIsEditCategoryMod(false));
                dispatch(changeCategoryDescription(newCategoryDescription));
                setNewCategoryDescription("");
              }}
            >
              <IoMdCheckmark />
            </button>
            <button onClick={() => dispatch(setIsEditCategoryMod(false))}>
              <AiOutlineClose />
            </button>
          </div>
        ) : (
          <button
            onClick={() => {
              dispatch(setIsEditCategoryMod(true));
            }}
          >
            <MdEdit />
          </button>
        )
      ) : null}
    </div>
  );
};
