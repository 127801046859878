import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isShowAside: false,
};

export const aside = createSlice({
  name: "aside",
  initialState,
  reducers: {
    toggleAside: (state) => {
      state.isShowAside = !state.isShowAside;
    },
    closeAside: (state) => {
      state.isShowAside = false;
    },
  },
});

export const { toggleAside, closeAside } = aside.actions;
export default aside.reducer;
