import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import ImageDataService from '../../services/images.service';

const initialState = {
  activeCategoryName: 'all',
  activeCategoryID: null,
  categories: [],
  isCategoryDescription: false,
  isEditCategoryMod: false,
};

export const getCategories = createAsyncThunk(
  'category/getCategories',
  async (_, { rejectWithValue, dispatch }) => {
    const data = await ImageDataService.getAllCategories();
    dispatch(
      setCategories(data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))),
    );
  },
);

export const deleteCategory = createAsyncThunk(
  'category/deleteCategory',
  async (id, { rejectWithValue, dispatch, getState }) => {
    await ImageDataService.deleteCategory(id);
    dispatch(getCategories());
  },
);

export const changeCategoryDescription = createAsyncThunk(
  'category/changeCategoryDescription',
  async (description, { rejectWithValue, dispatch, getState }) => {
    const { activeCategoryID } = getState().categories;
    await ImageDataService.updateCategory(activeCategoryID, { description });
    dispatch(getCategories());
  },
);

const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    setActiveCategoryName(state, action) {
      state.activeCategoryName = action.payload;
    },
    setActiveCategoryID(state, action) {
      state.activeCategoryID = action.payload;
    },
    setCategories(state, action) {
      state.categories = action.payload;
    },
    setIsCategoryDescription(state, action) {
      state.isCategoryDescription = action.payload;
    },
    setIsEditCategoryMod(state, action) {
      state.isEditCategoryMod = action.payload;
    },
  },
  extraReducers: {
    [getCategories.pending]: () => console.log('categories pending'),
    [getCategories.fulfilled]: () => console.log('categories fulfilled'),
    [getCategories.rejected]: () => console.log('categories rejected'),

    [deleteCategory.pending]: () => console.log('delete category pending'),
    [deleteCategory.fulfilled]: () => console.log('delete category fulfilled'),
    [deleteCategory.rejected]: () => console.log('delete category rejected'),
  },
});

export const {
  setActiveCategoryName,
  setActiveCategoryID,
  setCategories,
  setIsCategoryDescription,
  setIsEditCategoryMod,
} = categorySlice.actions;
export default categorySlice.reducer;
