import React, { useEffect, useRef, useState } from 'react';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';

import styles from './dropdown.module.scss';

export const Dropdown = (props) => {
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const dropdownRef = useRef();

  const handleClickOutside = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setIsOpenDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className={styles.dropdown} ref={dropdownRef}>
      <div
        className={styles.dropdown__top}
        onClick={() => setIsOpenDropdown(!isOpenDropdown)}
      >
        {props.type === 'select' ? (
          <p>{props.category ? props.category : 'select category'}</p>
        ) : (
          <input
            type="text"
            placeholder="Category"
            value={props.category}
            onClick={
              isOpenDropdown
                ? (e) => {
                    e.stopPropagation();
                  }
                : null
            }
            onChange={(e) => props.setCategory(e.target.value)}
          />
        )}
        <MdOutlineKeyboardArrowDown
          className={`${styles.arrow} ${
            isOpenDropdown ? styles.rotate180 : ''
          }`}
        />
      </div>
      {isOpenDropdown && (
        <div className={styles.dropdown__listWrapper}>
          <div className={styles.list}>
            {props.categories &&
              props.categories.map((category) => (
                <p
                  key={category.id}
                  value={category.urlParamName}
                  onClick={() => {
                    props.setCategory(category.name);
                    setIsOpenDropdown(false);
                  }}
                >
                  {category.name}
                </p>
              ))}
            {props.type === 'select' && (
              <p
                onClick={() => {
                  props.setCategory('');
                  setIsOpenDropdown(false);
                }}
              >
                WITHOUT CATEGORY
              </p>
            )}
            {props.categories.length === 0 && <p>Empty :(</p>}
          </div>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
