import React from "react";

import { cn } from "utils/cn";

export const MenuButton = ({ children, onClick, className }) => {
  return (
    <button
      className={cn("text-xl text-black p-1 w-fit rounded-full bg-slate-50", className)}
      onClick={onClick}
    >
      {children}
    </button>
  );
};
