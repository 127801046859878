import React, { useEffect, useRef, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";

import { setSliderSInitialPhoto } from "redux/slices/imagesSlice";
import { setIsShowSlider } from "redux/slices/sliderSlice";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div
      className="absolute right-4 top-1/2 z-20 flex -translate-y-12 transform items-center justify-center rounded-full bg-black bg-opacity-50 p-2.5 text-3xl leading-none"
      onClick={onClick}
    >
      <button>
        <BsChevronRight />
      </button>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div
      className="absolute left-4 top-1/2 z-20 flex -translate-y-12 transform items-center justify-center rounded-full bg-black bg-opacity-50 p-2.5 text-3xl leading-none"
      onClick={onClick}
    >
      <button>
        <BsChevronLeft />
      </button>
    </div>
  );
}

export const GallerySlider = () => {
  const dispatch = useDispatch();
  const sliderRef = useRef(null);
  const { imagesForGallerySlider, sliderSInitialPhoto } = useSelector(
    (state) => state.images,
  );

  const initialSlide = sliderSInitialPhoto
    ? imagesForGallerySlider.findIndex((image) => image === sliderSInitialPhoto)
    : 0;

  const [activeSlideIndex, setActiveSlideIndex] = useState(initialSlide);

  const afterChange = (index) => {
    setActiveSlideIndex(index);
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    initialSlide: initialSlide,
    afterChange: afterChange,
  };

  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.click();
    }
  }, []);

  return (
    <div className="[&_.slide]: fixed left-0 top-0 z-50 h-full w-full bg-black bg-opacity-40 text-white backdrop-blur [&_.slick-list]:h-full [&_.slick-list]:w-full [&_.slick-slide>*]:flex [&_.slick-slide>*]:h-full [&_.slick-slide>*]:w-full [&_.slick-slide>*]:items-center [&_.slick-slide>*]:justify-center [&_.slick-slide]:flex [&_.slick-slide]:h-full [&_.slick-slide]:w-full [&_.slick-slide]:items-center [&_.slick-slide]:justify-center [&_.slick-slider]:mt-12 [&_.slick-slider]:flex [&_.slick-slider]:h-[80vh] [&_.slick-track]:flex [&_.slick-track]:h-full [&_.slick-track]:w-full [&_.slick-track]:items-center [&_.slick-track]:justify-center">
      <button
        className="absolute right-2 top-2 z-20 p-1 text-3xl leading-none"
        onClick={() => {
          dispatch(setIsShowSlider(false));
          dispatch(setSliderSInitialPhoto(""));
        }}
      >
        <AiOutlineClose />
      </button>
      <Slider {...settings} >
        {imagesForGallerySlider.map((image) => (
          <div className="h-full w-full" key={image}>
            <img
              className="h-full w-full object-contain "
              src={image}
              alt={image}
            />
          </div>
        ))}
      </Slider>
      <div className="mt-2.5 text-center">
        <p>
          {activeSlideIndex + 1} / {imagesForGallerySlider.length}
        </p>
      </div>
    </div>
  );
};
