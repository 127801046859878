import { storage } from 'firebase.config';
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytesResumable,
} from 'firebase/storage';
import React, { useEffect, useRef, useState } from 'react';
import { MdEdit } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { getBannerBgImage } from 'redux/slices/bannerSlice';

import ImageDataService from '../services/images.service';

export const Background = () => {
  const dispatch = useDispatch();
  const [newBg, setNewBg] = useState(null);
  const inputRef = useRef(null);

  const user = useSelector((state) => state.user.user);

  const uploadPhoto = (e) => {
    const imageFile = e.target.files[0];
    const storageRef = ref(storage, `Banner/${Date.now()}-${imageFile.name}`);
    const uploadTask = uploadBytesResumable(storageRef, imageFile);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const uploadProgress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log(`Upload is ${uploadProgress}% done`);
      },
      (error) => {
        console.log(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setNewBg(downloadURL);
        });
      },
    );
  };

  const submitPhoto = async () => {
    deleteObject(ref(storage, bannerBgImage?.bgUrl));

    const newImg = {
      bgUrl: newBg,
    };

    await ImageDataService.changeBannerBg('WP4SdUYOoDA9lH1rTBPv', newImg);

    dispatch(getBannerBgImage());
  };

  useEffect(() => {
    if (newBg) {
      submitPhoto();
    }
  }, [newBg]);

  useEffect(() => {
    dispatch(getBannerBgImage());
  }, []);

  const { bannerBgImage } = useSelector((state) => state.banner);
  return (
    <div
      className='fixed top-0 left-0 w-screen h-screen bg-cover bg-center'
      style={{
        backgroundImage: `url(${bannerBgImage.bgUrl})`,
      }}
    >

      {user &&
        (user.email === process.env.REACT_APP_MENULIS ||
          user.email === process.env.REACT_APP_ALEX ||
          user.email === process.env.REACT_APP_JULIA) && (
          <div className="absolute bottom-2 left-2 ">
            <input className='hidden' type="file" onChange={uploadPhoto} ref={inputRef} />
            <button  className='drop-shadow-lg' onClick={() => inputRef.current.click()}>
              <MdEdit />
            </button>
          </div>
        )}
    </div>
  );
};
