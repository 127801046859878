import React, { useEffect, useState } from 'react';
import { AiOutlineClose, AiOutlineCloudUpload } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { getCategories } from 'redux/slices/categorySlice';

import { setSelectedPhotoSet } from '../../../redux/slices/imagesSlice';
import { toggleEditPopup } from '../../../redux/slices/popupsSlice';
import ImageDataService from '../../../services/images.service';
import { Dropdown } from '../../Dropdown';
import styles from './edit.module.scss';

export const EditPopup = () => {
  const dispatch = useDispatch();
  const { selectedPhotoSet } = useSelector((state) => state.images);

  const { categories } = useSelector((state) => state.categories);
  const [category, setCategory] = useState('');
  const [message, setMessage] = useState({ error: false, msg: '' });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');

    const newCategory = {
      name: category.toUpperCase(),
    };

    const newImageData = {
      category,
    };

    try {
      if (selectedPhotoSet !== undefined && selectedPhotoSet !== '') {
        await ImageDataService.updateImage(selectedPhotoSet, newImageData);
        dispatch(setSelectedPhotoSet(null));
        setMessage({ error: false, msg: 'Updated successfully!' });

        if (!categories.find((cat) => cat.name === category)) {
          await ImageDataService.addCategory(newCategory);
          dispatch(getCategories());
        }
      }
    } catch (err) {
      setMessage({ error: true, msg: err.message });
    }

    setCategory('');
    setTimeout(() => {
      setMessage('');
      dispatch(toggleEditPopup(false));
    }, 1000);
  };

  const getPhotoInfo = async () => {
    setMessage('');
    try {
      const docSnap = await ImageDataService.getImage(selectedPhotoSet);
      setCategory(docSnap.data().category);
    } catch (err) {
      setMessage({ error: true, msg: err.message });
    }
  };

  useEffect(() => {
    if (selectedPhotoSet !== undefined && selectedPhotoSet !== '') {
      getPhotoInfo();
    }
  }, []);

  return (
    <div className={`popupWrapper ${styles.editPopup}`}>
      <form className="popup" onSubmit={handleSubmit}>
        <header>
          <h3>Edit category</h3>
          <button
            className="close"
            onClick={() => {
              dispatch(toggleEditPopup(false));
              dispatch(setSelectedPhotoSet(null));
            }}
          >
            <AiOutlineClose />
          </button>
        </header>
        <Dropdown
          type="select"
          categories={categories}
          category={category}
          setCategory={setCategory}
        />
        <button className="greenButton btn" type="submit">
          <p>Edit</p>
          <div className="btn__icon">
            <AiOutlineCloudUpload />
          </div>
        </button>
        {message?.msg && (
          <div onClose={() => setMessage('')}>{message?.msg}</div>
        )}
      </form>
    </div>
  );
};
