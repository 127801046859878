import React from "react";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";

import { AllRightsReserved } from "components/AllRightsReserved";
import { Aside } from "components/Aside";
import { AsideSwitcher } from "components/AsideSwitcher";
import { GallerySlider } from "components/Gallery/Slider";
import { Header } from "components/Header";
import { Menu } from "components/Menu";
import { Popups } from "components/Popups";
import { Content } from "ui-elements/Content";
import { ContentWrapper } from "ui-elements/ContentWrapper";

import { Background } from "./components/Background";
import { Home } from "./pages/Home";
import "./styles/global.scss";
import "./styles/variables.scss";

function App() {
  const { user } = useSelector((state) => state.user);
  const { isShowSlider } = useSelector((state) => state.slider);
  const { isShowAside } = useSelector((state) => state.aside);
  return (
    <div className="app relative">
      <Background />
      {isShowAside && <Aside />}
      <ContentWrapper>
        {user &&
          (user.email === process.env.REACT_APP_MENULIS ||
            user.email === process.env.REACT_APP_ALEX ||
            user.email === process.env.REACT_APP_JULIA) && <Menu />}
        <Content>
          <Header />
          {/* <div className="relative overflow-hidden"> */}
          {/* <AsideSwitcher className="z-30 hidden md:absolute md:right-5 md:top-24 md:flex" /> */}
          {/* <Routes> */}
          {/* <Route path="/" element={} />
            <Route path="/contacts" element={<Contacts />} /> */}
          <Home />
          {/* </Routes> */}
          {/* </div> */}
        </Content>
      </ContentWrapper>
      <Popups />
      {isShowSlider && <GallerySlider />}
    </div>
  );
}

export default App;
